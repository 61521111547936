import Carousel from 'react-bootstrap/Carousel';
import React, { useState, useEffect, useRef } from 'react';

import './my.css';

import AOS from 'aos';
import Typed from 'react-typed';
import 'aos/dist/aos.css'; // Import AOS CSS

function Lending() {
  return (
    <section id="home" className="hero section">
    {/* <img
      src="assets/img/herobg.jpeg"
      alt=""
      data-aos="fade-in"
      className="herobg"
    /> */}
    <div className="container">
     
      
      <div className="row justify-content-center" data-aos="zoom-out">
        
        <div className="col-xl-7 col-lg-10 text-center">
          <h2 className="text-light">Association Of Certified <br></br> Fraud Examiners <br></br>Oman Chapter</h2>
        {/* <AnimatedHeading />
        <AnimatedParagraph /> */}
        </div>
      </div>
      <div className="text-center" data-aos="zoom-out" data-aos-delay={100}>
  <a href="/about" className="btn-get-started ">
    About Us
  </a>
</div>
      
    </div>
  </section>
  )
}

export default Lending
import React from 'react';
import './my.css';
function About() {
  return (
    <section id="about" className="about section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2 className='aboutus'>
        About Us
        <br />
      </h2>
      <p className='aboutpara' style={{ textAlign: 'justify' }}>
      "The ACFE is the world's largest anti-fraud organization and premier provider of anti-fraud training and education. Together with nearly 85,000 members, the ACFE is reducing business fraud worldwide and inspiring public confidence in the integrity and objectivity within the profession.

The Oman Chapter of the ACFE was established in 2015 under a Memorandum of Understanding with the College of Banking and Financial Studies. The main aims of the Chapter are to spread awareness about Fraud and fraud prevention and to promote the profession"

      </p>
    </div>
    {/* End Section Title */}
    <div className="container">
    <div className="row justify-content-center" data-aos="zoom-out">
    <div className="col-xl-7 col-lg-9 text-center" data-aos="fade-up" data-aos-delay={100}>
     
  <img 
    src="assets/img/ACFE/acfelogo.png" 
    alt="Description of the image" 
    style={{ 
      maxWidth: '100%', 
      height: 'auto',
      opacity: "40%" 
    }}
  />
</div>
<br/>
<div className="text-center" data-aos="zoom-out" data-aos-delay={100}>
     <a href='#about-alt'><button type="button" class="btn btn-success">Read More</button></a>
      </div>
        
</div>
      
    </div>
  </section>
  )
}

export default About
import React from 'react';
import { Navigation, Pagination, Autoplay } from 'swiper/react';
import Swiper, { SwiperSlide } from 'swiper/react';


// Install Swiper and its modules: npm install swiper react-swiper

// Configure Swiper
function BoardMembersDirectory() {
    const swiperConfig = {
        loop: true,
        speed: 600,
        autoplay: {
          delay: 5000,
        },
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable:   
     true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween:   
     20,
          },
        },
      };
    
      return (
        <>
  <section id="portfolio" className="portfolio section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2 className='aboutus'>Board Members Directory</h2>
    </div>
    {/* End Section Title */}
    <div className="container">
      <div
        className="isotope-layout"
        data-default-filter="*"
        data-layout="masonry"
        data-sort="original-order"
      >
        {/* <ul
          className="portfolio-filters isotope-filters"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <li data-filter="*" className="filter-active">
            All
          </li>
        </ul> */}
        {/* End Portfolio Filters */}
        <div
          className="row gy-4 isotope-container"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
            <img
              src="assets/img/ACFE/MembersCards/1.png"
              className="img-fluid"
              alt=""
            />
            <br></br>
            <div className="portfolio-info">
              <h4 className='cardtit '>.</h4>
              <a
                href="assets/img/ACFE/MembersCards/1.png"
                title="App 1"
                data-gallery="portfolio-gallery-app"
                className="glightbox preview-link"
              >
                <i className="bi bi-zoom-in" />
              </a>
            </div>
          </div>
          {/* End Portfolio Item */}
          <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
            <img
              src="assets/img/ACFE/MembersCards/2.png"
              className="img-fluid"
              alt=""
            />
            <br></br>
            <div className="portfolio-info">
              <h4 className='cardtit '>.</h4>
              <a
                href="assets/img/ACFE/MembersCards/2.png"
                title="App 1"
                data-gallery="portfolio-gallery-app"
                className="glightbox preview-link"
              >
                <i className="bi bi-zoom-in" />
              </a>
            </div>
          </div>
          {/* End Portfolio Item */}
          <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
            <img
              src="assets/img/ACFE/MembersCards/3.png"
              className="img-fluid"
              alt=""
            />
            <br></br>
            <div className="portfolio-info">
              <h4 className='cardtit '>.</h4>
              <a
                href="assets/img/ACFE/MembersCards/3.png"
                title="App 1"
                data-gallery="portfolio-gallery-app"
                className="glightbox preview-link"
              >
                <i className="bi bi-zoom-in" />
              </a>
            </div>
          </div>
          {/* End Portfolio Item */}
          <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
            <img
              src="assets/img/ACFE/MembersCards/4.png"
              className="img-fluid"
              alt=""
            />
            <br></br>
            <div className="portfolio-info">
              <h4 className='cardtit '>.</h4>
              <a
                href="assets/img/ACFE/MembersCards/4.png"
                title="App 1"
                data-gallery="portfolio-gallery-app"
                className="glightbox preview-link"
              >
                <i className="bi bi-zoom-in" />
              </a>
            </div>
          </div>
          {/* End Portfolio Item */}
          <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
            <img
              src="assets/img/ACFE/MembersCards/5.png"
              className="img-fluid"
              alt=""
            />
            <br></br>
            <div className="portfolio-info">
              <h4 className='cardtit '>.</h4>
              <a
                href="assets/img/ACFE/MembersCards/5.png"
                title="App 1"
                data-gallery="portfolio-gallery-app"
                className="glightbox preview-link"
              >
                <i className="bi bi-zoom-in" />
              </a>
            </div>
          </div>
          {/* End Portfolio Item */}
          <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
            <img
              src="assets/img/ACFE/MembersCards/6.png"
              className="img-fluid"
              alt=""
            />
            <br></br>
            <div className="portfolio-info">
              <h4 className='cardtit '>.</h4>
              <a
                href="assets/img/ACFE/MembersCards/6.png"
                title="App 1"
                data-gallery="portfolio-gallery-app"
                className="glightbox preview-link"
              >
                <i className="bi bi-zoom-in" />
              </a>
            </div>
          </div>
          {/* End Portfolio Item */}
          <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
            <img
              src="assets/img/ACFE/MembersCards/7.png"
              className="img-fluid"
              alt=""
            />
            <br></br>
            <div className="portfolio-info">
              <h4 className='cardtit '>.</h4>
              <a
                href="assets/img/ACFE/MembersCards/7.png"
                title="App 1"
                data-gallery="portfolio-gallery-app"
                className="glightbox preview-link"
              >
                <i className="bi bi-zoom-in" />
              </a>
            </div>
          </div>
          {/* End Portfolio Item */}
          <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
            <img
              src="assets/img/ACFE/MembersCards/8.png"
              className="img-fluid"
              alt=""
            />
            <br></br>
            <div className="portfolio-info">
              <h4 className='cardtit '>.</h4>
              <a
                href="assets/img/ACFE/MembersCards/8.png"
                title="App 1"
                data-gallery="portfolio-gallery-app"
                className="glightbox preview-link"
              >
                <i className="bi bi-zoom-in" />
              </a>
            </div>
          </div>
          {/* End Portfolio Item */}
          <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
            <img
              src="assets/img/ACFE/MembersCards/9.png"
              className="img-fluid"
              alt=""
            />
            <br></br>
            <div className="portfolio-info">
              <h4 className='cardtit '>.</h4>
              <a
                href="assets/img/ACFE/MembersCards/9.png"
                title="App 1"
                data-gallery="portfolio-gallery-app"
                className="glightbox preview-link"
              >
                <i className="bi bi-zoom-in" />
              </a>
            </div>
          </div>
          {/* End Portfolio Item */}
          <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
            <img
              src="assets/img/ACFE/MembersCards/10.png"
              className="img-fluid"
              alt=""
            />
            <br></br>
            <div className="portfolio-info">
              <h4 className='cardtit '>.</h4>
              <a
                href="assets/img/ACFE/MembersCards/10.png"
                title="App 1"
                data-gallery="portfolio-gallery-app"
                className="glightbox preview-link"
              >
                <i className="bi bi-zoom-in" />
              </a>
            </div>
          </div>
          {/* End Portfolio Item */}
        </div>
        {/* End Portfolio Container */}
      </div>
    </div>
  </section>
  {/* /Portfolio Section */}
</>


      );
    };
    

export default BoardMembersDirectory
import Carousel from 'react-bootstrap/Carousel';
import { BrowserRouter as Router, Routes, Route, Link, BrowserRouter } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import AnimatedHeading from './AnimatedHeading';
import AnimatedParagraph from './AnimatedParagraph';

import './my.css';

import AOS from 'aos';
import Typed from 'react-typed';
import 'aos/dist/aos.css'; // Import AOS CSS
import Lending from './Lending';
import About from './About';
import ACFEOMANLEADERSHIP from './ACFEOMANLEADERSHIP';
import Currentboard from './Currentboard';
import Board from './Board';
import Events from './Events';
import NewsAndUpdates from './NewsAndUpdates';
import PresidentMessage from './PresidentMessage';
import ContactUs from './ContactUs';
function App() {
/* HEADING  */


  


  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: 'ease-in-out',
      once: true
    });
  }, []);
  return (
    <>
  
    <BrowserRouter>
  <Routes>
        <Route path="/" element={<Lending/>} />
        <Route path="about" element={<About/>} />
        <Route path="acfeomanleadership" element={<ACFEOMANLEADERSHIP/>} />
        <Route path="currentboard" element={<Currentboard/>} />
        <Route path="Board" element={<Board/>} />
        <Route path="Events" element={<Events/>} />
        <Route path="NewsAndUpdates" element={<NewsAndUpdates/>} />
        <Route path="PresidentMessage" element={<PresidentMessage/>} />
        <Route path="contactus" element={<ContactUs/>} />
      </Routes>
    </BrowserRouter>
  


  {/* Hero Section */}
  {/* <Lending/> */}
  {/* /Hero Section */}
  {/* <div>
    <Carousel fade>
      <Carousel.Item>
        <img className='d-block w-100 h-100' src='assets\img\resize-audit.jpg'></img>
        <Carousel.Caption className="d-flex flex-column align-items-center justify-content-center">
          <h3 className="text-center carousel-heading"><b>Auditing</b></h3>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img className='d-block w-100' src='assets\img\tax.jpg'></img>
        <Carousel.Caption>
        <h3 className="text-center carousel-heading"><b>Tax Services</b></h3>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img className='d-block w-100' src='assets\img\cimg.jpg'></img>
      
        <Carousel.Caption>
        <h3 className="text-center carousel-heading"><b>Consulting</b></h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
      
    </div> */}
  {/* About Section */}
  {/* <About/>
  <ACFEOMANLEADERSHIP/> */}
  {/* /About Section */}
  {/* MIssion Vision Section */}
 
  {/* MIssion Vision Section */}
  
  {/* Clients Section */}
 
  {/* /Clients Section */}
  {/* Services Section */}
 
  {/* /Services Section */}
  {/* Call To Action Section */}
  {/* <section id="call-to-action" className="call-to-action section">
    <div className="container">
      <div
        className="row justify-content-center"
        data-aos="zoom-in"
        data-aos-delay={100}
      >
        <div className="col-xl-10">
          <div className="text-center">
            <h3>Company Executive Summary</h3>
            <p style={{ textAlign: 'justify' }}>
            An Omani Audit firm managed by a team of experts specialized in Auditing & Assurance, Accounting, Tax, financial, administrative, & economic consulting services. We have experience in Financial Standards (IFRS) & Auditing Standards. Also, we have expertise in the field of corporate tax & value-added tax (VAT). Our team has the following qualifications:
MBA – CISA – CFE – PMP – CDPSE – CICS – CA – Oman VAT Diploma

            </p>
            <a className="cta-btn" href="#contact">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  </section> */}
{/* REGISTER*/}


  {/* /Call To Action Section */}
  {/* Team Section */}
  {/* /Team Section */}
  {/* Pricing Section */}
  
  {/* /Pricing Section */}
  {/* Faq Section */} 
  {/* /Faq Section */}
  {/* Contact Section */}
 
  {/* /Contact Section */}






  </>
  );
}

export default App;

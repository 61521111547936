import React from 'react'

function PresidentMessage() {
  return (
    <section id="about" className="about section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2 className='aboutus'>
        President Message
        <br />
      </h2>
      <p className='aboutpara' style={{ textAlign: 'justify' }}>
      Dear Fellow Members,<br></br>

Greetings from the ACFE Oman Chapter!
Many thanks for all your overwhelming congratulatory wishes for all of us. Our prayers, thoughts are with each of you, and we hope this message  finds you, your family, and your friends in good health.
It is my honor and privilege to be elected as the President of the Chapter, the most prestigious professional Anti-Fraud body in Sultanate of Oman. I humbly accept to carry out the responsibility as the 4th President of the ACFE Oman Chapter.
During the existence of our chapter in the last 7 years, we have made good progress. The success we achieved is not because of any one individual but with the collective efforts and work done by our founders, past Presidents, leaders and members year on year. We are certainly proud of the fact that CBFS, our sponsors and we are an integral part of the our chapter journey in Oman.<br></br><br></br>

Thanks to all our founders, leaders, members, sponsors’ and other stakeholders who support us in all our endeavors. But this success is incomplete if we don’t thank enough the Late His Majesty Sultan Qaboos Bin Said Al Said and His Majesty Haitham Bin Tariq Al Said and people of the Sultanate of Oman for the opportunities given to all of us to grow and advance in this land of opportunities.<br></br><br></br>

I have been part of the Chapter for the past two years and today with all humility and passion I take on this role to serve you, along with the other Board members. I welcome the newly elected board members and wish them all the best in their efforts in contributing and improving the Chapter activities. Year after years witnessed value added improvement in the chapter activities and the entire team is committed to sustain this growth and to maintain the quality and standards of the Chapter <br></br><br></br>
In line with ACFE’s vision to be the world’s largest Accounting Body and to have robust regulatory mechanism, the Chapter core objective includes: <br></br><br></br>
• Facilitating continual professional education to the members and finance professionals<br></br>
• Facilitating employment opportunities for members<br></br>
• Harnessing technology to deliver better services to our members<br></br>
• Organizing programs as a part of the ACFE CSR initiative<br></br>
• Profession as Partner in Development<br></br> 
• Interaction with Govt. Authorities<br></br> 
• Provide a platform to enhance the value of members<br></br>
• Continue Student initiatives<br></br><br></br>

The incoming Board will organize high-quality events and programs to achieve the chapter objectives. We  request  your active engagement in all our events, your assistance in bringing those members of ACFE who are still not members of the Oman Chapter and suggest names of top-notch speakers for our upcoming CPE events.<br></br><br></br>
The Board aspires to connect with the members and other stakeholders so as to provide platform for the benefits of our sponsors, partners and other stakeholders.<br></br><br></br>
The members are the strength of the active chapter, and an active chapter is the privilege of any member. Each one of us is so proud to be a CFE. We wish you all to stand together with us and work little for better result. It is important to know each one of your professional backgrounds better to plan the activities better for us and others in days to come.<br></br><br></br>
I take this opportunity along with all my board colleagues to thank each one of you for the continued support to the Chapter and the members. Once again extend our sincere thanks to all the members for the active support and request all to come forward to join hands with us for the Profession and ACFE.<br></br><br></br>
I look forward to working with you all because I believe that <b>“Coming together is a Beginning, keeping together is Progress and working together is Success”</b>.<br></br><br></br>

Thanks & Best regards,<br></br>

Khalil Nabhan Mohammed Al Rawahi<br></br>
President<br></br>
ACFE – Oman Chapter<br></br>
      </p>
    </div>
    {/* End Section Title */}
    <div className="container">
    <div className="row justify-content-center" data-aos="zoom-out">
    <div className="col-xl-7 col-lg-9 text-center" data-aos="fade-up" data-aos-delay={100}>
     
  <img 
    src="assets/img/ACFE/acfelogo.png" 
    alt="Description of the image" 
    style={{ 
      maxWidth: '100%', 
      height: 'auto',
      opacity: "40%" 
    }}
  />
</div>
<br/>
</div>
      
    </div>
  </section>
  )
}

export default PresidentMessage
import React from 'react'

function ContactUs() {
  return (<>
    <section id="contact" className="contact section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2 className='aboutus'>Contact Us</h2>
    </div>
    {/* End Section Title */}
    <div className="container" data-aos="fade-up" data-aos-delay={100}>
      <div className="mb-4" data-aos="fade-up" data-aos-delay={200}>
        <iframe
          style={{ border: 0, width: "100%", height: 270 }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.4080576550787!2d58.399892400000006!3d23.5537837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e91fe41f47b9f6d%3A0x157170654d975def!2zQ29sbGVnZSBPZiBCYW5raW5nIEFuZCBGaW5hbmNlIFNlcnZpY2VzINmD2YTZitipINin2YTYr9ix2KfYs9in2Kog2KfZhNmF2LXYsdmB2YrYqSDZiCDYp9mE2YXYp9mE2YrYqQ!5e0!3m2!1sen!2s!4v1724533384200!5m2!1sen!2s"
          frameBorder={0}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
      {/* End Google Maps */}
      <div className="row ">
        <div className="col-lg-4">
          <div
            className="info-item d-flex"
            data-aos="fade-up"
            data-aos-delay={300}
          >
            <i className="bi bi-geo-alt flex-shrink-0" />
            <div>
              <h3 className='aboutus'>Address</h3>
              <p > College of Banking and Financial Studies -  Boushar, Muscat - Sultanate of Oman</p>
            </div>
          </div>
          {/* End Info Item */}
          <div
            className="info-item d-flex"
            data-aos="fade-up"
            data-aos-delay={400}
          >
            <i className="bi bi-telephone flex-shrink-0" />
            <div>
              <h3 className='aboutus'>Call Us</h3>
              <p>+968 7750 0758</p>
            </div>
          </div>
          {/* End Info Item */}
          <div
            className="info-item d-flex"
            data-aos="fade-up"
            data-aos-delay={500}
          >
            <i className="bi bi-envelope flex-shrink-0" />
            <div>
              <h3 className='aboutus'>Email Us</h3>
              <p>info@acfeoman.com</p>
            </div>

            <br></br><br></br><br></br><br></br>
          </div>
          {/* End Info Item */}
        </div>
        
        {/* End Contact Form */}
      </div>
    </div>
  </section>
  {/* /Contact Section */}
</>
  )
}

export default ContactUs

import React from 'react'

function Board() {
  return (
    <section id="about" className="about section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2 className='aboutus'>
        2021-2022
        <br />
      </h2>
    </div>
    {/* End Section Title */}
    <div className="container">
    <div className="row justify-content-center" data-aos="zoom-out">
    <div className="col-xl-7 col-lg-9 text-center" data-aos="fade-up" data-aos-delay={100}>
     
  <img 
    src="assets/img/ACFE/Board.png" 
    alt="Description of the image" 
    style={{ 
      maxWidth: '100%', 
      height: 'auto' 
    }}
  />
</div>
<br/>

        
</div>
      
    </div>
  </section>
  )
}

export default Board;
import React from 'react'
import './my.css';
function ACFEOMANLEADERSHIP() {
  return (
    <section id="about" className="about section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2 className='aboutus'>
        ACFE Oman Leadership
        <br />
      </h2>
      <p className='aboutpara' style={{ textAlign: 'justify' }}>
      "The Association of Certified Fraud Examiners (ACFE) Oman Chapter announced that Mr.Khalil Nabhan Mohammed Al Rawahi, has taken over as the President and other positions as follows:
   <br/> Vice President: <b>Mr. Murad Al Bulushi</b>
   <br/>Secretary:<b> Mr. Prashant Bafna </b>
   <br/>Treasurer & International Relations:<b> Mr. N Ramananda Prabhu</b>
   <br/>Past President:<b> Mr. Jose Chacko</b>
   <br/>Director Training:<b> Ms. Fathiya Al Maskari</b>
   <br/>Director Membership:<b> Mr. Haitham Al Raisi</b>
   <br/>Director Media & Technology:<b> Mr. Hassan Ahmed</b>
   <br/>Director at Large:<b> Mr. Salim Al Mushaifri"</b>
      </p>
    </div>
    {/* End Section Title */}
    <div className="container">
    <div className="row justify-content-center" data-aos="zoom-out">
    <div className="col-xl-7 col-lg-9 text-center" data-aos="fade-up" data-aos-delay={100}>
     
  <img 
    src="assets/img/ACFE/acfelogo.png" 
    alt="Description of the image" 
    style={{ 
      maxWidth: '100%', 
      height: 'auto',
      opacity:"40%"
      
    }}
  />
</div>
<br/>
<div className="text-center" data-aos="zoom-out" data-aos-delay={100}>
     <a href='#about-alt'><button type="button" class="btn btn-success">Read More</button></a>
      </div>
        
</div>
      
    </div>
  </section>
  )
}

export default ACFEOMANLEADERSHIP
import React, { useState, useEffect, useRef } from 'react';
import './my.css';
import AOS from 'aos';
import BoardMembersDirectory from './BoardMembersDirectory';


function Currentboard() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: 'ease-in-out',
      once: true
    });
  }, []);
  const [showContent, setShowContent] = useState(false);

  const handleButtonClick = () => {
    setShowContent(!showContent);
  };
  return (
    <section id="about" className="about section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2 className='aboutus'>
        Current Board
        <br />
      </h2>
    </div>
    {/* End Section Title */}
    <div className="container">
    <div className="row justify-content-center" data-aos="zoom-out">
    <div className="col-xl-7 col-lg-9 text-center" data-aos="fade-up" data-aos-delay={100}>
     
  <img 
    src="assets/img/ACFE/currentboard.png" 
    alt="Description of the image" 
    style={{ 
      maxWidth: '100%', 
      height: 'auto' 
    }}
  />
</div>


<div className="text-center mt-10 " data-aos="zoom-out" data-aos-delay={100}>
  <a id="boardmembersbtn" href="#boardmembersbtn" className="btn-get-started currentboard " onClick={handleButtonClick}>
  Board Members Directory
  </a>
</div>
{showContent && (
        <BoardMembersDirectory></BoardMembersDirectory>
      )}
       
</div>
      
    </div>
  </section>
  )
}

export default Currentboard
import React from 'react'

function Events() {
  return (
    <section id="about" className="about section">
    {/* Section Title */}
    <div className="container section-title" data-aos="fade-up">
      <h2 className='aboutus'>
        Events & Calender
        <br />
      </h2>
    </div>
    {/* End Section Title */}
    <div className="container">
    <div className="row justify-content-center" data-aos="zoom-out">
    <div className="col-lg-3 text-center" data-aos="fade-up" data-aos-delay={100}>
    <p className='aboutpara' style={{ textAlign: 'justify' }}>
    You are cordially invited to attend a seminar on 📲 Smartphone Security and Privacy hosted by ACFE Oman Chapter , ISACA Muscat Chapter, and IIA Oman - Institute of Internal Auditors Oman (under sponsorship of College Of Banking & Financial Studies كلية الدراسات المصرفية والمالية. This event will be conducted by the esteemed trainer Mr. Sayyed Tabrez on August 27, 2024.

      </p>
    </div>  <br></br>
    <div className="col-lg-9 text-center" data-aos="fade-up" data-aos-delay={100}>
     
  <img 
    src="assets/img/ACFE/isacaevent.png" 
    alt="Description of the image" 
    style={{ 
      maxWidth: '100%', 
      height: 'auto' ,
    }}
  />
</div>
<br/>

        
</div>
      
    </div>
  </section>
  )
}

export default Events
import React, { useState, useEffect, useRef } from 'react';
import './my.css';
import AOS from 'aos';

function NewsAndUpdates() {
    useEffect(() => {
        AOS.init({
          duration: 1500,
          easing: 'ease-in-out',
          once: true
        });
      }, []);
  return (<>
    <section id="portfolio" className="portfolio section">
      {/* Section Title */}
      <div className="container section-title" data-aos="fade-up">
        <h2 className='aboutus'>News & Updates</h2>
      </div>
      {/* End Section Title */}
      <div className="container">
        <div
          className="isotope-layout"
          data-default-filter="*"
          data-layout="masonry"
          data-sort="original-order"
        >
          {/* End Portfolio Filters */}
          <div
            className="row gy-4 isotope-container"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
              <img
                src="assets/img/ACFE/NewsAndUpdates/1.png"
                className="img-fluid h-100"
                alt=""
              />
              <div className="portfolio-info">
                <h4>Article</h4>
                
                <a
                  href="assets/img/ACFE/NewsAndUpdates/1.png"
                  title="App 1"
                  data-gallery="portfolio-gallery-app"
                  className="glightbox preview-link"
                >
                  <i className="bi bi-zoom-in" />
                </a>
               
              </div>
            </div>
            {/* End Portfolio Item */}
            <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
              <img
                src="assets/img/ACFE/NewsAndUpdates/2.png"
                className="img-fluid h-100"
                alt=""
              />
              <div className="portfolio-info">
                <h4>Article</h4>
                
                <a
                  href="assets/img/ACFE/NewsAndUpdates/2.png"
                  title="Product 1"
                  data-gallery="portfolio-gallery-product"
                  className="glightbox preview-link"
                >
                  <i className="bi bi-zoom-in" />
                </a>
                
              </div>
            </div>
            {/* End Portfolio Item */}
            <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-branding">
              <img
                src="assets/img/ACFE/NewsAndUpdates/3.png"
                className="img-fluid h-100"
                alt=""
              />
              <div className="portfolio-info">
                <h4>Article</h4>
                
                <a
                  href="assets/img/ACFE/NewsAndUpdates/3.png"
                  title="Branding 1"
                  data-gallery="portfolio-gallery-branding"
                  className="glightbox preview-link"
                >
                  <i className="bi bi-zoom-in" />
                </a>
                
              </div>
            </div>
            {/* End Portfolio Item */}
            <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
              <img
                src="assets/img/ACFE/NewsAndUpdates/4.png"
                className="img-fluid h-100"
                alt=""
              />
              <div className="portfolio-info">
                <h4>Article</h4>
                
                <a
                  href="assets/img/ACFE/NewsAndUpdates/4.png"
                  title="App 2"
                  data-gallery="portfolio-gallery-app"
                  className="glightbox preview-link"
                >
                  <i className="bi bi-zoom-in" />
                </a>
                
              </div>
            </div>
            {/* End Portfolio Item */}
            <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
              <img
                src="assets/img/ACFE/NewsAndUpdates/5.png"
                className="img-fluid h-100"
                alt=""
              />
              <div className="portfolio-info">
                <h4>Article</h4>
                
                <a
                  href="assets/img/ACFE/NewsAndUpdates/5.png"
                  title="Product 2"
                  data-gallery="portfolio-gallery-product"
                  className="glightbox preview-link"
                >
                  <i className="bi bi-zoom-in" />
                </a>
                
              </div>
            </div>
            {/* End Portfolio Item */}
           
            {/* End Portfolio Item */}
            <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
              <img
                src="assets/img/ACFE/NewsAndUpdates/7.png"
                className="img-fluid h-100"
                alt=""
              />
              <div className="portfolio-info">
                <h4>Article</h4>
                
                <a
                  href="assets/img/ACFE/NewsAndUpdates/7.png"
                  title="App 3"
                  data-gallery="portfolio-gallery-app"
                  className="glightbox preview-link"
                >
                  <i className="bi bi-zoom-in" />
                </a>
                
                
              </div>
            </div>
            {/* End Portfolio Item */}
            <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
              <img
                src="assets/img/ACFE/NewsAndUpdates/8.png"
                className="img-fluid h-100"
                alt=""
              />
              <div className="portfolio-info">
                <h4>Article</h4>
                
                <a
                  href="assets/img/ACFE/NewsAndUpdates/8.png"
                  title="Product 3"
                  data-gallery="portfolio-gallery-product"
                  className="glightbox preview-link"
                >
                  <i className="bi bi-zoom-in" />
                </a>
                
              </div>
            </div>
            {/* End Portfolio Item */}
            <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-branding">
              <img
                src="assets/img/ACFE/NewsAndUpdates/9.png"
                className="img-fluid h-100"
                alt=""
              />
              <div className="portfolio-info">
                <h4>Article</h4>
                
                <a
                  href="assets/img/ACFE/NewsAndUpdates/9.png"
                  title="Branding 2"
                  data-gallery="portfolio-gallery-branding"
                  className="glightbox preview-link"
                >
                  <i className="bi bi-zoom-in" />
                </a>
              </div>
            </div>
            {/* End Portfolio Item */}
          </div>
          {/* End Portfolio Container */}
        </div>
      </div>
    </section>
    {/* /Portfolio Section */}
    </>
  )
}

export default NewsAndUpdates